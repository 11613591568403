@font-face {
font-family: '__ittybitRegular_f2fb21';
src: url(/_next/static/media/eac54ead33779c12-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__ittybitRegular_Fallback_f2fb21';src: local("Arial");ascent-override: 68.18%;descent-override: 22.73%;line-gap-override: 90.91%;size-adjust: 110.00%
}.__className_f2fb21 {font-family: '__ittybitRegular_f2fb21', '__ittybitRegular_Fallback_f2fb21';font-weight: 400;font-style: normal
}.__variable_f2fb21 {--font-ittybit-regular: '__ittybitRegular_f2fb21', '__ittybitRegular_Fallback_f2fb21'
}

@font-face {
font-family: '__ittybitBold_978b34';
src: url(/_next/static/media/33872dc5d4098e71-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__ittybitBold_Fallback_978b34';src: local("Arial");ascent-override: 67.66%;descent-override: 22.55%;line-gap-override: 90.21%;size-adjust: 110.85%
}.__className_978b34 {font-family: '__ittybitBold_978b34', '__ittybitBold_Fallback_978b34';font-weight: 900;font-style: normal
}.__variable_978b34 {--font-ittybit-bold: '__ittybitBold_978b34', '__ittybitBold_Fallback_978b34'
}

@font-face {
font-family: '__ittybitNarrow_c8db8c';
src: url(/_next/static/media/e22bedf58deca89a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__ittybitNarrow_Fallback_c8db8c';src: local("Arial");ascent-override: 90.45%;descent-override: 30.15%;line-gap-override: 120.60%;size-adjust: 82.92%
}.__className_c8db8c {font-family: '__ittybitNarrow_c8db8c', '__ittybitNarrow_Fallback_c8db8c';font-weight: 400;font-style: normal
}.__variable_c8db8c {--font-ittybit-narrow: '__ittybitNarrow_c8db8c', '__ittybitNarrow_Fallback_c8db8c'
}

@font-face {
font-family: '__ittybitMono_f09420';
src: url(/_next/static/media/7ec4023362a490be-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__ittybitMono_Fallback_f09420';src: local("Arial");ascent-override: 51.54%;descent-override: 17.18%;line-gap-override: 68.71%;size-adjust: 145.53%
}.__className_f09420 {font-family: '__ittybitMono_f09420', '__ittybitMono_Fallback_f09420';font-weight: normal;font-style: normal
}.__variable_f09420 {--font-ittybit-mono: '__ittybitMono_f09420', '__ittybitMono_Fallback_f09420'
}

@font-face {
font-family: '__inter_d340a1';
src: url(/_next/static/media/c91adde9f78caceb-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}

@font-face {
font-family: '__inter_d340a1';
src: url(/_next/static/media/0336a89fb4e7fc1d-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__inter_Fallback_d340a1';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_d340a1 {font-family: '__inter_d340a1', '__inter_Fallback_d340a1'
}.__variable_d340a1 {--font-inter: '__inter_d340a1', '__inter_Fallback_d340a1'
}

/* vietnamese */
@font-face {
  font-family: '__Inconsolata_262b13';
  font-style: normal;
  font-weight: 200 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a46e2414bfa52a92-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inconsolata_262b13';
  font-style: normal;
  font-weight: 200 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/eea71804552b3264-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inconsolata_262b13';
  font-style: normal;
  font-weight: 200 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/a6f8c6b7131e657a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inconsolata_Fallback_262b13';src: local("Arial");ascent-override: 76.59%;descent-override: 16.94%;line-gap-override: 0.00%;size-adjust: 112.16%
}.__className_262b13 {font-family: '__Inconsolata_262b13', '__Inconsolata_Fallback_262b13';font-style: normal
}.__variable_262b13 {--font-inconsolata: '__Inconsolata_262b13', '__Inconsolata_Fallback_262b13'
}

